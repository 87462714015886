import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _parseInt from 'lodash/parseInt';
import { CircularProgress } from '@mui/material';
import { MdKeyboardArrowRight } from 'react-icons/md';
import useAddDocumentationMembers from './useAddDocumentationMembers';
import { getReferrerParams } from '../../../helpers/ReferrerParams';
import GroupChartingNotes from '../../documentation/GroupChartingNotes';
import AddDocumentation from '../member_encounter_history/AddDocumentation';
import MembersList from '../../documentation/MembersList';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import Tabs from '../../Tabs';
import useChartingNoteExternalIds from '../../documentation/useChartingNoteExternalIds';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';

const TABS = [
  {
    id: 1,
    name: 'Panel Summary',
  },
];

function AddGroupDocumentation({ authenticityToken, membersListUrl, states, smartPhrases }) {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const { memberIds, sessionIdentifier } = useMemo(() => {
    const { add_group_documentation_json_params } = getReferrerParams();
    const { member_ids, session_identifier } = JSON.parse(add_group_documentation_json_params);
    return {
      memberIds: member_ids?.split(',')?.map(_parseInt),
      sessionIdentifier: session_identifier,
    };
  }, []);

  const { members, isLoading } = useAddDocumentationMembers(memberIds);
  const { chartingNoteExternalIds } = useChartingNoteExternalIds({
    sessionIdentifier,
  });

  const groupNotesMembers = useMemo(
    () =>
      members?.map(({ id, mrn, healthie_mrn, first_name, last_name }) => ({
        id,
        mrn,
        healthieMrn: healthie_mrn,
        firstName: first_name,
        lastName: last_name,
      })),
    [members]
  );
  const membersList = convertObjKeysToCamelCase(members, ['variables'])?.map(
    ({ id, firstName, lastName, groupInformation, memberSummaries, mrn, dob }) => ({
      id,
      firstName,
      lastName,
      groupInformation,
      memberSummaries,
      mrn,
      dob,
    })
  );

  return (
    <>
      {isLoading && (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )}
      {members?.length && (
        <div className="flex gap-2 items-center mx-8 py-8 border-b border-gray-400">
          {membersListUrl && (
            <>
              <a className="font-body text-3xl font-bold text-teal-700 underline" href={membersListUrl} target="_top">
                Members
              </a>
              <MdKeyboardArrowRight className="w-8 h-8 text-gray-600" />
            </>
          )}
          <h2 className="font-body font-bold text-gray-800">Add Documentation</h2>
        </div>
      )}
      {members?.length > 1 && (
        <>
          <div className="mx-8">
            <h3 className="font-body font-bold mt-8 mb-6">{`Members (${members.length})`}</h3>
            <Tabs tabs={TABS} activeTab={activeTab} onChange={setActiveTab} />
            <div className="mt-5 mb-8">{membersList?.length && <MembersList members={membersList} />}</div>
          </div>
          <GroupChartingNotes
            authenticityToken={authenticityToken}
            members={groupNotesMembers}
            states={states}
            sessionIdentifier={sessionIdentifier}
            selectedChartingNoteIds={chartingNoteExternalIds.length ? chartingNoteExternalIds : undefined}
            smartPhrases={smartPhrases}
          />
        </>
      )}
      {members?.length === 1 && (
        <AddDocumentation
          member={members[0]}
          authenticityToken={authenticityToken}
          states={states}
          smartPhrases={smartPhrases}
        />
      )}
    </>
  );
}

AddGroupDocumentation.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  membersListUrl: PropTypes.string,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  smartPhrases: SmartPhrasesPropType,
};

AddGroupDocumentation.defaultProps = {
  membersListUrl: null,
  smartPhrases: null,
};

export default AddGroupDocumentation;
