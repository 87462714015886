import React, { useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import { debounce } from 'lodash';

// COMPONENTS
import Filters from '../Filters';

// HELPERS
import { labelStyle } from '../uds_supplies_dashboard/shared/styles';
import useHttp from './hooks/use-http';

const DEFAULT_PLACEHOLDER = 'Search by first name, last name, or MRN';
const DEFAULT_LABEL = 'Member';

export const filterOption = (option, inputValue) => {
  if (!option?.value) return false;

  const { first_name, last_name, mrn, healthie_mrn, ecw_id, email, phone_number } = option.value || {};

  const textValue = inputValue?.trim()?.toLowerCase();

  return (
    first_name?.toLowerCase().includes(textValue) ||
    last_name?.toLowerCase().includes(textValue) ||
    mrn?.toLowerCase().includes(textValue) ||
    healthie_mrn?.toLowerCase().includes(textValue) ||
    ecw_id?.toLowerCase().includes(textValue) ||
    email?.toLowerCase().includes(textValue) ||
    phone_number?.toLowerCase().includes(textValue)
  );
};

function EmpanelmentMemberSearchFilter({ testID, value, onSelect, error, placeholder, label, multiSelect }) {
  const { sendRequest, isLoading } = useHttp();
  const [members, setMembers] = useState([]);

  const searchMembers = async (query) => {
    try {
      const response = await sendRequest(`/staff/empanelment_members`, {
        method: 'GET',
        params: {
          page: 1,
          search_text: query,
        },
      });

      const parsedMembers = response?.json_data.map((member) => ({
        value: member,
        label: member.mrn ? `${member.member_name} (${member.mrn})` : member.member_name,
      }));
      setMembers(parsedMembers);
    } catch (e) {
      window.Sentry.captureException(e);
    }
  };

  return (
    <Filters
      isSearch
      labelStyle={labelStyle}
      label={label}
      placeholder={placeholder}
      data={members}
      value={multiSelect ? value ?? [] : [value]}
      error={error}
      stateChanger={(selected) => onSelect(multiSelect ? selected : selected.at(-1) ?? null)}
      onHandleSearch={debounce((query) => searchMembers(query), 100)}
      isLoading={isLoading}
      testID={testID}
      filterOption={filterOption}
    />
  );
}

EmpanelmentMemberSearchFilter.propTypes = {
  testID: PropTypes.string,
  value: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  multiSelect: PropTypes.bool,
};

EmpanelmentMemberSearchFilter.defaultProps = {
  testID: 'empanelment-member-search',
  error: null,
  placeholder: DEFAULT_PLACEHOLDER,
  label: DEFAULT_LABEL,
  multiSelect: false,
};

export default EmpanelmentMemberSearchFilter;
