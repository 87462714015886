import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Picker from 'rc-picker';
import enUS from 'rc-picker/lib/locale/en_US';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { PiCalendarBlankBold } from 'react-icons/pi';
import InputWrapper from './InputWrapper';
import 'rc-picker/assets/index.css';

const DateInput = forwardRef(
  (
    {
      label,
      name,
      value,
      onBlur,
      onChange,
      placeholder,
      error,
      disabled,
      required,
      isVertical,
      picker,
      minDate,
      isSuffixIconVisible,
    },
    ref
  ) => {
    const isTimePicker = picker === 'time';

    const getInputValue = () => {
      if (moment(value).isValid()) {
        if (isTimePicker) return moment(value, 'LT');

        return moment(value);
      }

      return '';
    };

    const onInputChange = (dateTime) => {
      if (isTimePicker) {
        onChange(dateTime?.format('LT'));
        return;
      }

      onChange(dateTime?.format('YYYY-MM-DD'));
    };

    const suffixIcon = isSuffixIconVisible && (
      <div className="flex justify-center items-center w-6 h-6">
        <PiCalendarBlankBold className="w-5 h-5 text-gray-700" />
      </div>
    );

    return (
      <InputWrapper label={label} name={name} required={required} error={error} isVertical={isVertical}>
        <Picker
          data-testid="date-input"
          locale={enUS}
          generateConfig={momentGenerateConfig}
          ref={ref}
          name={name}
          value={getInputValue()}
          onBlur={onBlur}
          onChange={onInputChange}
          needConfirm={false}
          placeholder={placeholder}
          disabled={disabled}
          className={classNames('shared-input date-input', {
            'shared-input-error': !!error,
            'left-clear-icon': isSuffixIconVisible,
          })}
          classNames={{ popup: 'date-input-popup' }}
          picker={picker}
          showTime={
            isTimePicker && {
              showHour: true,
              showMinute: true,
            }
          }
          use12Hours
          minDate={minDate}
          suffixIcon={suffixIcon}
        />
      </InputWrapper>
    );
  }
);

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
  picker: PropTypes.oneOf(['date', 'time']),
  minDate: PropTypes.string,
  isSuffixIconVisible: PropTypes.bool,
};

DateInput.defaultProps = {
  label: null,
  onBlur: null,
  placeholder: null,
  error: null,
  disabled: false,
  required: false,
  isVertical: true,
  picker: 'date',
  minDate: null,
  isSuffixIconVisible: false,
};

export default DateInput;
