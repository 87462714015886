import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoChevronForward } from 'react-icons/io5';
import Button from '../../shared/Button';
import ButtonPopup from '../../shared/ButtonPopup';
import EditTaskModal from '../shared/EditTaskModal';
import ReassignPrescriptions from '../shared/reassign/ReassignPrescriptions';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import usePrescriptionTaskActions from './usePrescriptionTaskActions';
import { useSelectedNotes } from './useSelectedNotes';
import useAssignees from './useAssignees';
import { RX_STATUS } from '../list/types';
import PrescriptionsForReviewModal from '../shared/PrescriptionsForReviewModal';

const noPermissionMessage = 'You can only edit tasks that are ready for review.';
const noDocumentationMessage = 'To proceed, document why Rx was not sent.';

function Header({
  authenticityToken,
  memberName,
  memberMrn,
  prescriptionQueueURL,
  prescriptionTaskDetails,
  prescriptionReasons,
  setPrescriptionTaskDetails,
  addToast,
  canEditLockedRx,
  isOtherPendingPrescription,
}) {
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [isPrescriptionsForReviewModalOpen, setIsPrescriptionsForReviewModalOpen] = useState(false);
  const { isLoading, rxSentAction, rxNotSentAction } = usePrescriptionTaskActions({
    prescriptionTaskDetails,
    prescriptionReasons,
    authenticityToken,
    addToast,
    redirectUrl: prescriptionQueueURL,
  });
  const { selectedNotes } = useSelectedNotes();
  const assignees = useAssignees();
  const hasDocumentation = !!selectedNotes?.length;

  const canEdit = prescriptionTaskDetails?.status === RX_STATUS.READY_FOR_REVIEW || canEditLockedRx;

  const onEditSuccess = (response) => {
    setPrescriptionTaskDetails(convertObjKeysToCamelCase(response));
    setIsEditTaskModalOpen(false);
  };

  const onReassignSuccess = (response) => {
    setPrescriptionTaskDetails(convertObjKeysToCamelCase(response?.prescription_tasks?.[0]));
    setIsReassignModalOpen(false);
  };

  const rxNotSentDisabledMessage = () => {
    if (!canEdit) {
      return noPermissionMessage;
    }

    if (!hasDocumentation) {
      return noDocumentationMessage;
    }

    return '';
  };

  const handleRxSentClick = () => {
    if (isOtherPendingPrescription) {
      setIsPrescriptionsForReviewModalOpen(true);
      return;
    }

    rxSentAction();
  };

  return (
    <div className="flex justify-between gap-3 pb-2.5 border-b border-gray-400">
      <div className="flex flex-row items-center gap-2">
        <a href={prescriptionQueueURL} target="_top" className="text-3xl font-bold underline">
          Prescription Queue
        </a>
        <IoChevronForward className="text-gray-600 w-7 h-7" />
        <h1 className="text-3xl font-bold font-body">{memberName}</h1>
      </div>

      <div className="flex gap-2.5">
        <ButtonPopup buttonLabel="task actions" isDisabled={!canEdit} disabledMessage={noPermissionMessage}>
          <>
            <Button onClick={() => setIsEditTaskModalOpen(true)} isDefaultButton={false}>
              Edit Task
            </Button>
            <Button
              onClick={() => setIsReassignModalOpen(true)}
              disabled={assignees.error}
              isLoading={assignees.isLoading}
              isDefaultButton={false}
              classes="whitespace-nowrap"
            >
              Reassign Task
            </Button>
          </>
        </ButtonPopup>
        <Button
          isWarning
          onClick={rxNotSentAction}
          testID="rx-not-sent-button"
          disabled={!hasDocumentation || isLoading || !canEdit}
          disabledMessage={rxNotSentDisabledMessage()}
        >
          rx not sent
        </Button>
        <Button
          isSuccess
          disabled={!canEdit || isLoading}
          disabledMessage={canEdit || noPermissionMessage}
          onClick={handleRxSentClick}
          testID="rx-sent-button"
        >
          rx sent
        </Button>
      </div>
      {isEditTaskModalOpen && (
        <EditTaskModal
          isOpen
          onClose={() => setIsEditTaskModalOpen(false)}
          memberName={memberName}
          memberMrn={memberMrn}
          prescriptionTaskDetails={prescriptionTaskDetails}
          authenticityToken={authenticityToken}
          prescriptionReasons={prescriptionReasons}
          onSuccess={onEditSuccess}
          isOtherPendingPrescription={isOtherPendingPrescription}
        />
      )}
      <ReassignPrescriptions
        authenticityToken={authenticityToken}
        isModalOpen={isReassignModalOpen}
        prescriptionTasks={[prescriptionTaskDetails]}
        assigneesList={assignees.assigneesData ?? []}
        onSuccess={onReassignSuccess}
        closeModal={() => setIsReassignModalOpen(false)}
      />
      <PrescriptionsForReviewModal
        isOpen={isPrescriptionsForReviewModalOpen}
        onClose={() => setIsPrescriptionsForReviewModalOpen(false)}
        onConfirm={rxSentAction}
        isLoading={isLoading}
        memberName={memberName}
        memberMrn={memberMrn}
      />
    </div>
  );
}

Header.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  memberName: PropTypes.string.isRequired,
  memberMrn: PropTypes.string.isRequired,
  prescriptionQueueURL: PropTypes.string.isRequired,
  prescriptionTaskDetails: PropTypes.object.isRequired,
  prescriptionReasons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  setPrescriptionTaskDetails: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  canEditLockedRx: PropTypes.bool.isRequired,
  isOtherPendingPrescription: PropTypes.bool,
};

Header.defaultProps = {
  isOtherPendingPrescription: false,
};

export default Header;
